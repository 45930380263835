import {NgModule} from '@angular/core';
import {CommonModule,} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {Routes, RouterModule} from '@angular/router';

import {LandingComponent} from './pages/landing/landing.component';
import {PortfolioComponent} from './pages/portfolio/portfolio.component';
import {PolicyComponent} from './pages/policy/policy.component';
import {ProductsComponent} from './pages/products/products.component';

const routes: Routes = [
    {path: '', component: LandingComponent},
    {path: 'portfolio', component: PortfolioComponent},
    {path: 'policy', component: PolicyComponent},
    {path: 'fajas', component: ProductsComponent},
];

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        RouterModule.forRoot(routes)
    ],
    exports: [],
})
export class AppRoutingModule {
}
