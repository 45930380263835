import {Component, Input} from '@angular/core';
import {NgbModal, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-modal-content',
    template: `
        <div class="modal-header">
            <h5 class="modal-title text-center">Contact Us</h5>
            <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div style="display: flex;">
                <i class="fa fa-phone" style="font-size: 24px;"></i>
                <a href="tel:+18608399961" style="color: black; padding-left: 25px;">+1 860-839-9961</a>
            </div>
            <div style="display: flex;">
                <i class="fa fa-envelope" style="font-size: 24px;"></i>
                <a href="mailto:nathalie@lascaribbenassalon.com" style="color: black; padding-left: 25px;">nathalie@lascaribbenassalon.com</a>
            </div>
            <div style="display: flex;">
                <i class="fa fa-facebook-square" style="font-size: 24px;"></i>
                <a href="https://www.facebook.com/LasCaribbenas" target="_blank" style="color: black; padding-left: 25px;">https://www.facebook.com/LasCaribbenas</a>
            </div>
            <div style="display: flex;">
                <i class="fa fa-phone" style="font-size: 24px;"></i>
                <a href="https://www.instagram.com/lascaribbenas" target="_blank" style="color: black; padding-left: 25px;">https://www.instagram.com/lascaribbenas</a>
            </div>
        </div>
    `
})
export class NgbdModalContent {
    @Input() name;

    constructor(public activeModal: NgbActiveModal) {
    }
}

@Component({
    selector: 'app-modal-component',
    templateUrl: './modal.component.html'
})
export class NgbdModalComponent {

    constructor(private modalService: NgbModal) {
    }

    open() {
        const modalRef = this.modalService.open(NgbdModalContent);
        modalRef.componentInstance.name = 'World';
    }
}
