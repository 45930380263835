import {Component, OnDestroy, OnInit} from '@angular/core';

@Component({
  selector: 'app-policy',
  templateUrl: './policy.component.html',
  styleUrls: ['./policy.component.css']
})
export class PolicyComponent implements OnInit, OnDestroy {

  constructor() { }

  ngOnInit(): void {
    const nav = document.getElementsByTagName('nav')[0]
    nav.classList.add('d-none');
  }

  ngOnDestroy() {
    const nav = document.getElementsByTagName('nav')[0]
    nav.classList.add('d-block');
  }

}
