import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-landing',
    templateUrl: './landing.component.html',
    styleUrls: ['./landing.component.scss']
})

export class LandingComponent implements OnInit {
  focus: any;
  focus1: any;

  portfolio = [
      {image: './assets/img/portfolio/hair1.jpg', description: 'Hair1'},
      {image: './assets/img/portfolio/hair2.jpg', description: 'Hair2'},
      {image: './assets/img/portfolio/hair3.jpg', description: 'Hair3'},
      {image: './assets/img/portfolio/hair4.jpg', description: 'Hair4'},
  ]

  constructor() { }

  ngOnInit() {}

}
